
const config = {
  "account": {
    "contact": "steve@funbrandsllc.com",
    "id": "fun-brands",
    "imageUrl": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/fun-brands/assets/img/profile.png"
  },
  "assets": {
    "img": {
      "brand": {
        "backgrounds": {
          "profile": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/fun-brands/assets/img/brand/backgrounds/profile.png"
        },
        "logo": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/fun-brands/assets/img/brand/logo.png",
        "logo-white": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/fun-brands/assets/img/brand/logo-white.png"
      }
    }
  },
  "footer": {
    "company": {
      "link": "https://funbrands.agency",
      "name": "Fun Brands, LLC"
    }
  },
  "theme": {
    "palette": {
      "color-1": "#F1EDE9",
      "color-2": "#0D0D0D",
      "color-3": "#6C5A3B",
      "color-4": "#E0B874",
      "color-5": "#B8B6B5"
    }
  }
}

export default config;
